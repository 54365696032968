import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import illustration2 from "../../../assets/images/appImages/illustration2.png";
import illustration3 from "../../../assets/images/appImages/waw1.png";
import illustration4 from "../../../assets/images/appImages/waw2.png";


export const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};



function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="illustration-container">
                  <img loading="lazy" src={illustration} alt="image" />
                </div>
                <div className="pt-2 row custom-row">
                  <div className="col-md-6 mb-2">
                    <div className="image__wrapper">
                      <img src={illustration3} alt="image" />
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="image__wrapper">
                      <img src={illustration4} alt="image" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">SENARTISANS</h3>
                <div className="custom-content">
                  Senartisans est une entreprise artisanale sénégalaise, née d'une combinaison unique de créativité, de durabilité et de savoir-faire traditionnel.
                  Située à Mbour, près de Dakar, l'entreprise transforme des draps recyclés en linge de maison raffiné, en fusionnant esthétique et conscience
                  environnementale. <br />
                  L’idée de Senartisans est venue à la fondatrice, une microbiologiste passionnée de créativité, pendant sa dernière année de doctorat en Allemagne.
                  Confinée chez elle pendant la pandémie de COVID-19, elle s'est rappelée le travail de sa mère, une femme courageuse et résiliente qui avait lancé
                  une petite fabrique de teinture artisanale (Thioup) à domicile. Cet héritage familial inspire aujourd'hui Senartisans à promouvoir un artisanat
                  authentique et durable.
                </div>
              </div>
            </div>
          </div>
          <div className="pb-0 who-we-are">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <CustomBeautyTitle title="Mission" />
                  <h3 className="custom-title">Notre mission</h3>
                  <div className="custom-content">
                    Notre mission est de promouvoir l’artisanat sénégalais à travers des collections modernes et intemporelles, tout en contribuant au développement
                    d'une économie locale responsable. <br />
                    Nous nous attachons à proposer du linge de maison éthique et durable.
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="illustration-container">
                    <img loading="lazy" src={illustration2} alt="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-0 who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-4">
                <CustomBeautyTitle title="Valeurs" />
                <h3 className="custom-title">Nos valeurs</h3>
                <div className="custom-content-wrapper">
                  <p className="pb-2 para custom-content">
                    Senartisans valorise l’artisanat sénégalais, où chaque création incarne l’authenticité
                    et le savoir-faire de nos artisans locaux, avec des pièces uniques confectionnées avec soin. Voici les
                    valeurs qui définissent notre engagement :
                  </p>
                  <p className="pb-2 para custom-content">
                    <strong>Durabilité : </strong>Transformer des draps recyclés en créations élégantes, réduisant l’impact
                    environnemental et offrant une seconde vie aux matériaux.
                  </p>
                  <p className="pb-2 para custom-content">
                    <strong>Authenticité : </strong>Mettre à l’honneur le savoir-faire artisanal sénégalais avec des créations
                    uniques, inspirées de la culture locale et alliant tradition et créativité.
                  </p>
                  <p className="pb-2 para custom-content">
                    <strong>Engagement social : </strong>Créer des emplois pour les jeunes et les femmes de la localité,
                    contribuant ainsi au développement de la communauté.
                  </p>
                  <p className="pb-2 para custom-content">
                    <strong>Qualité : </strong>Privilégier des finitions soignées et un service client irréprochable.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '5rem 0' }}></div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
